import React from "react"
import dynamic from "next/dynamic"
import Router from "next/router";
import ErrorIcon from '@mui/icons-material/Error';
import Button from "@mui/material/Button"
import Link from "next/link";
// import * as Sentry from "@sentry/react";
import Bugsnag from '@bugsnag/js'

const BoxContainer = dynamic (() => import('../js/Components/Reusables/BoxContainer'))

//500 error landing page
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
  
      // Define a state variable to track whether is an error or not
      this.state = { hasError: false }
    }
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI
  
      return { hasError: true }
    }
    
    componentDidCatch(error, errorInfo) {
      // You can use your own error logging service here
      // console.log(error, errorInfo);
      // this.interval = setInterval(() => {
      //   Router.back();
      //   clearInterval(this.interval);
      //   this.setState({
      //     hasError: false
      //   })
      // }, 5000);
      console.log(error, errorInfo)
      Bugsnag.notify(error)
      // Sentry.captureException(errorInfo);
    }


    // componentWillUnmount() {
    //   // Clear the interval right before component unmount
    //   clearInterval(this.interval);
    //   this.setState({
    //     hasError: false
    //   })
    // }
    

    render() {
      // Check if the error is thrown
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <BoxContainer className={"mt-2 text-center"}>
            <ErrorIcon color="danger" fontSize="large"  />
            <h2>Oops, something went wrong!</h2>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ hasError: false })}
            >
              Go Back
            </Button>
          </BoxContainer>
        )
      }
  
      // Return children components in case of no error
  
      return this.props.children
    }
  }
  
  export default ErrorBoundary